import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import { Breadcrumb } from 'antd'

import { CONTACT_PATH } from '@/const'

export function Contacts() {
	return (
		<section className="contact">
			<div className="container">
				<div className="contact__inner">
					<div className="contact__content">
						<Breadcrumb
							items={[
								{ title: 'Главная', href: '/' },
								{
									title: 'Контакты',
									href: `${CONTACT_PATH}`,
								},
							]}
						/>
						<div className="contact__title">Контакты</div>
						<div className="contact__wrap">
							<span className="contact__wrap-item contacts_item__address">
								<svg className="contact__icon contacts_icon__address">
									<use xlinkHref="#svg_address"></use>
								</svg>
								г. Оренбург, ул. Туркестанская 5, 2 этаж , офис 215
							</span>

							<span className="contact__wrap-item contacts_item__time">
								<svg className="contact__icon">
									<use xlinkHref="#svg_time"></use>
								</svg>
								Пн. - Пт. : 09:00 - 19:00
							</span>

							<a
								href="tel:+73532979622"
								className="contact__wrap-item contacts_item__phone"
							>
								<svg className="contact__icon">
									<use xlinkHref="#svg_phone"></use>
								</svg>
								97-96-22
							</a>

							<a
								href="tel:+79878479622"
								className="contact__wrap-item contacts_item__phone"
							>
								<svg className="contact__icon">
									<use xlinkHref="#svg_phone"></use>
								</svg>
								+7 (987) 847-96-22
							</a>

							<span className="contact__wrap-item contacts_item__mail">
								<svg className="contact__icon">
									<use xlinkHref="#svg_mail"></use>
								</svg>
								<a href="mailto:ot-oren56@yandex.ru">ot-oren56@yandex.ru</a>
							</span>
						</div>
					</div>
					<div className="contact__map">
						<YMaps>
							<Map
								defaultState={{ center: [51.774279, 55.133804], zoom: 15 }}
								width={'100%'}
								height={500}
							>
								<Placemark defaultGeometry={[51.774279, 55.133804]} />
							</Map>
						</YMaps>
					</div>
				</div>
			</div>
			<div id="hbspt" className="sr-only"></div>
		</section>
	)
}
